import { BASE_URL } from "@/src/common/config"
import { trimEnd } from "lodash"

import {
  FB_APP_ID,
  GOOGLE_SITE_VERIFICATION_1,
  GOOGLE_SITE_VERIFICATION_2,
  LANDING_PAGE_SEO_IMAGE,
  POCKET_SITE_VERIFICATION
} from "../../constants/configs"

const DEFAULT_TITLE = "Stears: Explore data-driven insights on Africa"

const DEFAULT_DESCRIPTION =
  "African companies, Transactions, countries and industries"
export const DEFAULT_REPORTS_DESCRIPTION =
  "Research, insights and deal briefings on Africa"

const DEFAULT_LINKED_DATA = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  name: "Stears",
  publisher: {
    "@type": "Organization",
    name: "Stears",
    url: "/about-us/",
    logo: LANDING_PAGE_SEO_IMAGE
  },
  description: "Stears is Africa's most comprehensive...",
  sameAs: [
    "https://www.facebook.com/stearsng/",
    "https://twitter.com/StearsInsights",
    "https://www.linkedin.com/company/stears"
  ]
}

const NO_INDEX_TAG = {
  name: "robots",
  content: "noindex"
}

type MetaNameTag = {
  name: string
}

type MetaPropertyTag = {
  property: string
}
type MetaTag = (MetaNameTag | MetaPropertyTag) & {
  content: string
  key?: string
}

type SEOSettingType = {
  title: string
  description: string
  logo: string
  tags: MetaTag[]
  linkedData: object
}

export type SEOProps = {
  description?: string
  title?: string
  imageURL?: string
  linkedData?: object
  favicon?: string
}

// inspired by https://cheatcode.co/tutorials/how-to-handle-seo-metadata-in-next-js#creating-an-seo-component
export const getSEOSetting = (
  currentPath: string,
  options: SEOProps = {}
): SEOSettingType => {
  const defaultImageURL = `${trimEnd(BASE_URL, "/")}${LANDING_PAGE_SEO_IMAGE}`
  const {
    title = DEFAULT_TITLE,
    description = DEFAULT_DESCRIPTION,
    imageURL = defaultImageURL,
    linkedData = DEFAULT_LINKED_DATA
  } = options

  const settings = {
    title,
    description,
    logo: defaultImageURL,
    tags: [
      {
        name: "google-site-verification",
        content: GOOGLE_SITE_VERIFICATION_1
      },
      {
        name: "google-site-verification",
        content: GOOGLE_SITE_VERIFICATION_2
      },
      {
        name: "pocket-site-verification",
        content: POCKET_SITE_VERIFICATION
      },
      {
        property: "og:url",
        content: currentPath
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:image:width",
        content: "1000"
      },
      {
        property: "og:image:height",
        content: "1000"
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:image",
        content: imageURL
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "fb:app_id",
        content: FB_APP_ID
      },
      {
        name: "twitter:card",
        content: "summary_large_image"
      },
      {
        name: "twitter:image",
        content: imageURL
      },
      {
        name: "twitter:site",
        content: "@stearsng"
      },
      {
        name: "twitter:url",
        content: currentPath
      },
      {
        name: "twitter:creator",
        content: "@stearsng"
      },
      {
        name: "twitter:title",
        content: title.slice(0, 60)
      },
      {
        name: "twitter:description",
        content: description
      }
    ],
    linkedData
  }

  if (process.env.NODE_ENV !== "production") {
    settings.tags.push(NO_INDEX_TAG)
  }

  return settings
}
