import {
  endOfToday,
  endOfYear,
  format,
  startOfYear,
  subMonths,
  subYears
} from "date-fns"

export const getDateRange = (
  rangeType?: string,
  selectedStartYear?: string | null,
  selectedEndYear?: string | null
) => {
  const end = endOfToday()
  let start

  switch (rangeType) {
    case "last_12_months":
      start = subMonths(end, 12)
      break
    case "last_3_years":
      start = subYears(end, 3)
      break
    case "last_5_years":
      start = subYears(end, 5)
      break
    case "custom_period": {
      if (selectedStartYear && selectedEndYear) {
        const customStart = startOfYear(new Date(selectedStartYear))
        const customEnd = endOfYear(new Date(selectedEndYear))

        return {
          start: format(customStart, "yyyy-MM-dd"),
          end: format(customEnd, "yyyy-MM-dd")
        }
      }
      return null
    }
    default:
      return null
  }

  return {
    start: format(start, "yyyy-MM-dd"),
    end: format(end, "yyyy-MM-dd")
  }
}
