import { PAGE_PREFIX_TO_HIDE_FRESHWORKS } from "@/src/common/business-layer/constants"
import useClarityUserTagging from "@/src/common/hooks/useClarityUserTagging"
import { useAnalytics } from "@/src/sb/components/Analytics"
import { HOTJAR_ID, V2_FEATURE_TOGGLES } from "@/src/sb/constants/configs"
import { HUBSPOT_CHAT_WIDGET } from "@/src/sb/constants/featureFlags"
import { useUser } from "@/src/sb/hooks/useUser"
import * as Sentry from "@sentry/nextjs"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { hotjar } from "react-hotjar"

import { HubSpotChatWidget } from "../HubSpot"

const AppThirdParties = () => {
  const user = useUser()
  const analytics = useAnalytics()
  const { pathname } = useRouter()

  // Sentry user session
  useEffect(() => {
    if (user.data) {
      Sentry.setUser({
        id: String(user.data.user_id),
        email: user.data.email
      })
    } else {
      // assumes a user is logged out
      Sentry.setUser(null)
    }

    // also covers for anonymous users
    Sentry.setTag("user_type", user.userType)
  }, [user])

  useClarityUserTagging(user as any)

  // hotjar user session
  useEffect(() => {
    if (!hotjar.initialized()) hotjar.initialize(HOTJAR_ID, 6)

    if (user.data) {
      hotjar.identify(String(user.data?.user_id), {
        email: user.data.email,
        user_type: user.userType
      })
    }
  }, [user])

  useEffect(() => {
    const xwindow = window as any
    if (
      PAGE_PREFIX_TO_HIDE_FRESHWORKS.some((pathPrefix) =>
        pathname.startsWith(pathPrefix)
      )
    ) {
      // prevent error when FreshworksWidget is not initialized
      xwindow?.FreshworksWidget?.("hide", "launcher")
    } else {
      xwindow?.FreshworksWidget?.("show", "launcher")
    }
  }, [pathname])

  // analytics
  useEffect(() => {
    /**
     * Whenever the User ID changes, it must be identified in mixpanel.
     * See "Multiple users, single device" https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
     * This will naturally happen after every login or sign up
     */
    if (user?.data?.sub) {
      analytics.tagCurrentUser(user)
    }
  }, [user?.data, analytics])

  return (
    <>
      {V2_FEATURE_TOGGLES.has(HUBSPOT_CHAT_WIDGET) ? (
        <HubSpotChatWidget />
      ) : null}
    </>
  )
}

export default AppThirdParties
