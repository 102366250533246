import { ClarityLoggedInUserProps } from "@/src/sb/business-layer/types"
import { useEffect } from "react"

const useClarityUserTagging = (user: ClarityLoggedInUserProps) => {
  useEffect(() => {
    if (!user) return

    const globalWindow = window as any

    if (globalWindow.clarity) {
      globalWindow.clarity("set", {
        is_paid_corporate_subscriber_licence:
          user.corporate_subscriber_licence_on_trial,
        corporate_subscriber_licence: user.corporate_subscriber_licence,
        email: user.email,
        user_id: user.user_id
      })
    }
  }, [user])
}

export default useClarityUserTagging
