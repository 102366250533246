import { DialingCodeSelectOption } from "@/src/common/components/Select"
import countries from "@/src/common/data-layer/datasources/countries.json"
import { OptionType } from "@/src/common/types"
import { ENERGY_PRICE_TRACKER } from "@/src/elections/business-layer/constants"
import { DateRangeFilter } from "@/src/graphql/types"
import { ENERGY_PRICE_TRACKER_OBJECT } from "@/src/sb/constants"
import { getDateRange } from "@/src/sb/core/utils/financialsDateRange"
import { sortBy } from "lodash"

// Analytics
export const ANALYTICS_SUBSCRIBE_BUTTON = "subscribe_button"
export const ANALYTICS_SUBSCRIBE = "subscribe"

// Miscellaneous
export const PAGE_PREFIX_TO_HIDE_FRESHWORKS = [
  "/elections",
  "/premium/article",
  "/article",
  "/embeddable",
  "/deals",
  "/transactions",
  "/screener",
  "/companies",
  "/transactions"
]

export const SELECT_INPUT_OTHER_VALUE = "Other"

export const JOB_TITLE_OPTIONS = sortBy(
  [
    { value: "Analyst", label: "Analyst" },
    { value: "Associate", label: "Associate" },
    { value: "Consultant", label: "Consultant" },
    { value: "Portfolio Manager", label: "Portfolio Manager" },
    { value: "Vice President", label: "Vice President" },
    { value: "Director", label: "Director" },
    {
      value: "Chief Investment Officer",
      label: "Chief Investment Officer"
    },
    {
      value: "Managing Director/CEO",
      label: "Managing Director/CEO"
    },
    { value: "Researcher", label: "Researcher" }
  ],
  (jobTitle) => jobTitle.label
).concat({ value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE })

export const SENIORITY_OPTIONS = [
  { value: "C-Level Executive", label: "C-Level Executive" },
  { value: "Vice President", label: "Vice President" },
  { value: "Director", label: "Director" },
  { value: "Head of Department", label: "Head of Department" },
  { value: "Manager", label: "Manager" },
  { value: "Senior Level", label: "Senior Level" },
  { value: "Mid Level", label: "Mid Level" },
  { value: "Entry Level", label: "Entry Level" },
  { value: "Student", label: "Student" },
  { value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE }
]

export const INDUSTRY_OPTIONS = sortBy(
  [
    { value: "Asset Management", label: "Asset Management" },
    { value: "Banking & Finance", label: "Banking & Finance" },
    { value: "Consulting", label: "Consulting" },
    { value: "Equity Markets", label: "Equity Markets" },
    { value: "International Development", label: "International Development" },
    { value: "Family Office", label: "Family Office" },
    { value: "FinTech", label: "FinTech" },
    { value: "FMCG", label: "FMCG" },
    { value: "Government", label: "Government" },
    { value: "Hedge Fund", label: "Hedge Fund" },
    { value: "Investment Banking", label: "Investment Banking" },
    { value: "Law Firm", label: "Law Firm" },
    { value: "Market Research", label: "Market Research" },
    { value: "Mutual Fund", label: "Mutual Fund" },
    { value: "Pension Fund", label: "Pension Fund" },
    { value: "Private Equity", label: "Private Equity" },
    { value: "Public Debt", label: "Public Debt" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Policy", label: "Policy" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Insurance", label: "Insurance" }
  ],
  (jobTitle) => jobTitle.label
).concat({ value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE })

export const COMPANY_SIZE_OPTIONS = [
  { value: "1 - 5", label: "1 - 5" },
  { value: "5 - 25", label: "5 - 25" },
  { value: "25 - 50", label: "25 - 50" },
  { value: "50 - 100", label: "50 - 100" },
  { value: "100 - 500", label: "100 - 500" },
  { value: "500 - 1000", label: "500 - 1000" },
  { value: "1000+", label: "1000+" }
]

export const REFERRAL_SOURCE_OPTIONS = sortBy(
  [
    { value: "Google Search", label: "Google Search" },
    { value: "Twitter", label: "Twitter" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Email", label: "Email" },
    { value: "Online Event", label: "Online Event" },
    { value: "Offline Event", label: "Offline Event" },
    {
      value: "Television or Radio",
      label: "Television or Radio"
    },
    {
      value: "Professional network",
      label: "Professional network"
    }
  ],
  (jobTitle) => jobTitle.label
).concat({ value: SELECT_INPUT_OTHER_VALUE, label: SELECT_INPUT_OTHER_VALUE })

export const TOP_PREFERENCE_COUNTRY: Array<string> = [
  "NG",
  "GB",
  "US",
  "ZA",
  "KE"
]
const COUNTRY_CODE_OPTIONS_TOP: Array<DialingCodeSelectOption> = []
const COUNTRY_CODE_OPTIONS_BOTTOM: Array<DialingCodeSelectOption> = []

for (const country of countries as Array<{
  code: string
  dial_code: number
  flag: string
  name: string
}>) {
  const countryCode: DialingCodeSelectOption = {
    value: `+${country.dial_code}`,
    label: `+${country.dial_code}`,
    ...country
  }
  if (TOP_PREFERENCE_COUNTRY.includes(country.code)) {
    COUNTRY_CODE_OPTIONS_TOP.push(countryCode)

    continue
  }

  COUNTRY_CODE_OPTIONS_BOTTOM.push(countryCode)
}

export const COUNTRY_CODE_OPTIONS: Array<DialingCodeSelectOption> =
  TOP_PREFERENCE_COUNTRY.reduce(
    (options: Array<DialingCodeSelectOption>, countryCode) => {
      const option = COUNTRY_CODE_OPTIONS_TOP.find(
        (country) => country.code === countryCode
      )

      if (!option) return options

      return [...options, option]
    },
    []
  ).concat(COUNTRY_CODE_OPTIONS_BOTTOM)

export const SOCIAL_MEDIA_SIGNUP_KEY = "media"

export const PAGE_INTENT: Record<string, string> = {
  [ENERGY_PRICE_TRACKER_OBJECT.href]: ENERGY_PRICE_TRACKER
}

export const ARTICLE_PATH_MATCHES = [
  /^\/premium\/article\/.+/gi,
  /^\/article\/.+/gi
]

export const DATE_FORMAT = {
  monthDayYear: "MMMM d, y",
  shortMonthDayYear: "MMM d, y",
  dayMonthYear: "d MMMM, y"
}

export const currencyMap: {
  [key: string]: { label: string; symbol: string }
} = {
  AED: { label: "UAE Dirham", symbol: "AED" },
  AFN: { label: "Afghani", symbol: "AFN" },
  ALL: { label: "Lek", symbol: "ALL" },
  AMD: { label: "Armenian Dram", symbol: "AMD" },
  ANG: { label: "Netherlands Antillean Guilder", symbol: "ANG" },
  AOA: { label: "Kwanza", symbol: "AOA" },
  ARS: { label: "Argentine Peso", symbol: "ARS" },
  AUD: { label: "Australian Dollar", symbol: "A$" },
  AWG: { label: "Aruban Florin", symbol: "AWG" },
  AZN: { label: "Azerbaijan Manat", symbol: "AZN" },
  BAM: { label: "Convertible Mark", symbol: "BAM" },
  BBD: { label: "Barbados Dollar", symbol: "BBD" },
  BDT: { label: "Taka", symbol: "BDT" },
  BGN: { label: "Bulgarian Lev", symbol: "BGN" },
  BHD: { label: "Bahraini Dinar", symbol: "BHD" },
  BIF: { label: "Burundi Franc", symbol: "BIF" },
  BMD: { label: "Bermudian Dollar", symbol: "BMD" },
  BND: { label: "Brunei Dollar", symbol: "BND" },
  BOB: { label: "Boliviano", symbol: "BOB" },
  BOV: { label: "Mvdol", symbol: "BOV" },
  BRL: { label: "Brazilian Real", symbol: "R$" },
  BSD: { label: "Bahamian Dollar", symbol: "BSD" },
  BTN: { label: "Ngultrum", symbol: "BTN" },
  BWP: { label: "Pula", symbol: "BWP" },
  BYN: { label: "Belarusian Ruble", symbol: "BYN" },
  BZD: { label: "Belize Dollar", symbol: "BZD" },
  CAD: { label: "Canadian Dollar", symbol: "CA$" },
  CDF: { label: "Congolese Franc", symbol: "CDF" },
  CHF: { label: "Swiss Franc", symbol: "CHF" },
  CLF: { label: "Unidad de Fomento", symbol: "CLF" },
  CLP: { label: "Chilean Peso", symbol: "CLP" },
  CNY: { label: "Yuan Renminbi", symbol: "CN¥" },
  COP: { label: "Colombian Peso", symbol: "COP" },
  CRC: { label: "Costa Rican Colon", symbol: "CRC" },
  CUP: { label: "Cuban Peso", symbol: "CUP" },
  CVE: { label: "Cabo Verde Escudo", symbol: "CVE" },
  CZK: { label: "Czech Koruna", symbol: "CZK" },
  DKK: { label: "Danish Krone", symbol: "DKK" },
  DOP: { label: "Dominican Peso", symbol: "DOP" },
  DZD: { label: "Algerian Dinar", symbol: "DZD" },
  EGP: { label: "Egyptian Pound", symbol: "EGP" },
  ETB: { label: "Ethiopian Birr", symbol: "ETB" },
  EUR: { label: "Euro", symbol: "€" },
  FJD: { label: "Fiji Dollar", symbol: "FJD" },
  GBP: { label: "Pound Sterling", symbol: "£" },
  GHS: { label: "Ghana Cedi", symbol: "GHS" },
  GIP: { label: "Gibraltar Pound", symbol: "GIP" },
  GNF: { label: "Guinean Franc", symbol: "GNF" },
  GTQ: { label: "Quetzal", symbol: "GTQ" },
  GYD: { label: "Guyana Dollar", symbol: "GYD" },
  HKD: { label: "Hong Kong Dollar", symbol: "HK$" },
  HNL: { label: "Lempira", symbol: "HNL" },
  HRK: { label: "Kuna", symbol: "HRK" },
  HTG: { label: "Gourde", symbol: "HTG" },
  HUF: { label: "Forint", symbol: "HUF" },
  IDR: { label: "Rupiah", symbol: "IDR" },
  ILS: { label: "New Israeli Sheqel", symbol: "₪" },
  INR: { label: "Indian Rupee", symbol: "₹" },
  IQD: { label: "Iraqi Dinar", symbol: "IQD" },
  IRR: { label: "Iranian Rial", symbol: "IRR" },
  ISK: { label: "Iceland Krona", symbol: "ISK" },
  JMD: { label: "Jamaican Dollar", symbol: "JMD" },
  JOD: { label: "Jordanian Dinar", symbol: "JOD" },
  JPY: { label: "Yen", symbol: "¥" },
  KES: { label: "Kenyan Shilling", symbol: "KES" },
  KPW: { label: "North Korean Won", symbol: "KPW" },
  KRW: { label: "Won", symbol: "₩" },
  KWD: { label: "Kuwaiti Dinar", symbol: "KWD" },
  KZT: { label: "Tenge", symbol: "KZT" },
  LAK: { label: "Lao Kip", symbol: "LAK" },
  LBP: { label: "Lebanese Pound", symbol: "LBP" },
  LKR: { label: "Sri Lanka Rupee", symbol: "LKR" },
  MAD: { label: "Moroccan Dirham", symbol: "MAD" },
  MDL: { label: "Moldovan Leu", symbol: "MDL" },
  MKD: { label: "Denar", symbol: "MKD" },
  MMK: { label: "Kyat", symbol: "MMK" },
  MNT: { label: "Tugrik", symbol: "MNT" },
  MUR: { label: "Mauritius Rupee", symbol: "MUR" },
  MVR: { label: "Rufiyaa", symbol: "MVR" },
  MWK: { label: "Malawi Kwacha", symbol: "MWK" },
  MXN: { label: "Mexican Peso", symbol: "MX$" },
  MYR: { label: "Malaysian Ringgit", symbol: "MYR" },
  NAD: { label: "Namibia Dollar", symbol: "NAD" },
  NGN: { label: "Naira", symbol: "NGN" },
  NOK: { label: "Norwegian Krone", symbol: "NOK" },
  NPR: { label: "Nepalese Rupee", symbol: "NPR" },
  NZD: { label: "New Zealand Dollar", symbol: "NZ$" },
  OMR: { label: "Rial Omani", symbol: "OMR" },
  PHP: { label: "Philippine Peso", symbol: "₱" },
  PKR: { label: "Pakistan Rupee", symbol: "PKR" },
  PLN: { label: "Zloty", symbol: "PLN" },
  QAR: { label: "Qatari Rial", symbol: "QAR" },
  RON: { label: "Romanian Leu", symbol: "RON" },
  RSD: { label: "Serbian Dinar", symbol: "RSD" },
  RUB: { label: "Russian Ruble", symbol: "RUB" },
  SAR: { label: "Saudi Riyal", symbol: "SAR" },
  SDG: { label: "Sudanese Pound", symbol: "SDG" },
  SEK: { label: "Swedish Krona", symbol: "SEK" },
  SGD: { label: "Singapore Dollar", symbol: "SGD" },
  SYP: { label: "Syrian Pound", symbol: "SYP" },
  THB: { label: "Baht", symbol: "THB" },
  TND: { label: "Tunisian Dinar", symbol: "TND" },
  TRY: { label: "Turkish Lira", symbol: "TRY" },
  TWD: { label: "New Taiwan Dollar", symbol: "NT$" },
  TZS: { label: "Tanzanian Shilling", symbol: "TZS" },
  UAH: { label: "Hryvnia", symbol: "UAH" },
  UGX: { label: "Uganda Shilling", symbol: "UGX" },
  USD: { label: "US Dollar", symbol: "$" },
  VND: { label: "Dong", symbol: "₫" },
  ZAR: { label: "Rand", symbol: "ZAR" },
  ZMW: { label: "Zambian Kwacha", symbol: "ZMW" }
}

export const financialsCurrencyList = [
  { value: "reported_currency", label: "Reported Currency" },
  { value: "usd", label: "US Dollars ($)" }
]

export const DEFAULT_DEALS_TOOL_TIP_ALIGNMENT_AXIS = 24
export const DEFAULT_DEALS_MAIN_TOOL_TIP_AXIS = 16

export const LAST_FIVE_YEARS_FILTER = "last_5_years"

export const DEFAULT_LAST_FIVE_YEARS_FILTER: DateRangeFilter = getDateRange(
  LAST_FIVE_YEARS_FILTER
) ?? {
  start: "",
  end: ""
}

export const COMPANY_LIST_NODE_COLUMN = [
  {
    key: "financials_0_revenue",
    tooltipContent:
      "Total revenue is the total amount of money a company earns from selling its goods or services. It is calculated as quantity sold multiplied by price per unit."
  },
  {
    key: "financials_0_ebitda",
    tooltipContent:
      "EBITDA is a measure of a company's operating performance before non-operating costs like interest and taxes. It is calculated as operating profit + depreciation + amortisation."
  },
  {
    key: "financials_0_operatingExpenses",
    tooltipContent:
      "Operating expenses are costs associated with running a business that are not directly tied to the production of goods or services, such as rent, salaries, and utilities. It is calculated as total expenses − (COGS + non-operating expenses)"
  },
  {
    key: "financials_0_priceToEarningsRatio",
    tooltipContent:
      "Price to earnings ratio measures the relationship between a company’s share price and its earnings per share. It is useful in determining the market expectations of a company’s value. It is calculated as share price divided by earnings per share."
  },
  {
    key: "financials_0_evSales",
    tooltipContent:
      "EV/Sales can be used to determine the value of a company that might be acquired. It is useful for capital intensive industries (e.g. real estate, financial institutions). It is calculated as enterprise value divided by total revenue."
  },
  {
    key: "financials_0_evEbitda",
    tooltipContent:
      "EV/EBITDA measures a company's fair market value. It is useful for comparing companies with different capital structures. It is calculated as enterprise value divided by EBITDA."
  },
  {
    key: "financials_0_priceToBookRatio",
    tooltipContent:
      "Price to book ratio is used to assess valuation relative to a company's net assets; often relevant in asset-heavy industries like banking and real estate. It is calculated as share price divided by book value per share."
  },
  {
    key: "financials_0_debtToEquityRatio",
    tooltipContent:
      "Debt to equity ratio measures a company’s financial leverage by comparing its debt total liabilities to shareholder equity. It indicates how much debt a company uses to finance its operations relative to its own funds. It is the total debt divided by total equity."
  },
  {
    key: "financials_0_percentageReturnOnEquity",
    tooltipContent:
      "Return on equity (%) measures a company's profitability and efficiency in generating net income from shareholder investments. It is calculated as net income divided by average shareholder equity. It is calculated as net income divided by shareholders’ equity."
  },
  {
    key: "financials_0_percentageReturnOnAssets",
    tooltipContent:
      "Return on assets (%) is the ratio of net income to a company's total assets. It is relevant for assessing how effectively a company is using its assets to generate income. It is calculated by dividing net income by total assets."
  },
  {
    key: "financials_0_percentageReturnOnEquity",
    tooltipContent:
      "Return on equity (%) measures a company's profitability and efficiency in generating net income from shareholder investments. It is calculated as net income divided by average shareholder equity. It is calculated as net income divided by shareholders’ equity."
  },
  {
    key: "financials_0_percentageGrossMargin",
    tooltipContent:
      "Gross margin ratio (%) shows the percentage of revenue remaining after covering direct costs of production. It is the gross profit divided by revenue/sales."
  },
  {
    key: "financials_0_percentageNetProfitMargin",
    tooltipContent:
      "Net profit margin (%) shows the percentage of revenue retained as net income after all expenses. It is useful for gauging financial health and bottom-line performance. It is the net profit divided by revenue/sales."
  },
  {
    key: "financials_0_currentRatio",
    tooltipContent:
      "Current ratio assesses short-term liquidity by comparing current assets to current liabilities. It is valuable for evaluating financial stability. It is calculated by dividing total current assets by total current liabilities.."
  },
  {
    key: "transactionHistory_totalAmountRaised",
    tooltipContent:
      "Figures presented are based on the latest available disclosures from the reporting entity."
  },
  {
    key: "transactionHistory_lastTransactionAmount",
    tooltipContent:
      "Figures presented are based on the latest available disclosures from the reporting entity."
  },
  {
    key: "transactionHistory_latestTransactionType",
    tooltipContent:
      "Figures presented are based on the latest available disclosures from the reporting entity."
  },

  {
    key: "transactionHistory_latestFinancingType",
    tooltipContent:
      "Figures presented are based on the latest available disclosures from the reporting entity."
  }
]

export const CUSTOM_DATE_RANGE_OPTIONS: OptionType[] = [
  { value: "last_5_years", label: "Last 5 years" },
  { value: "last_10_years", label: "Last 10 years" },
  { value: "last_20_years", label: "Last 20 years" },
  { value: "is", label: "Is" },
  { value: "between", label: "Between" }
]

export const FINANCIAL_TRANSACTION_HISTORY_OPTIONS = [
  { label: "Below $1M", value: "below_1m" },
  { label: "1M$ and 1$0M", value: "between_1m_10m" },
  { label: "Above $10M", value: "above_10m" },
  { label: "Greater Than", value: "greater_than" },
  { label: "Less Than", value: "less_than" },
  { label: "Equal To", value: "equal" },
  { label: "Between", value: "between" }
]

export const FINANCIAL_FILTER_OPTIONS = {
  revenue: [
    { label: "Below $1M", value: "below_1m" },
    { label: "$1M to $10M", value: "between_1m_10m" },
    { label: "Above $10M", value: "above_10m" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],
  ebitda: [
    { label: "Below $1M", value: "below_1m" },
    { label: "$1M to $10M", value: "between_1m_10m" },
    { label: "Above $10M", value: "above_10m" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],
  operatingExpenses: [
    { label: "Below $1M", value: "below_1m" },
    { label: "$1M to $10M", value: "between_1m_10m" },
    { label: "Above $10M", value: "above_10m" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],
  percentagePriceToEarningRatio: [
    { label: "Below 10", value: "below_10" },
    { label: "10 to 20", value: "between_10_20" },
    { label: "Above 20", value: "above_20" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],

  priceToBookRatio: [
    { label: "Below 1", value: "below_1" },
    { label: "1 to 3", value: "between_1_3" },
    { label: "Above 3", value: "above_3" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],

  evSales: [
    { label: "Below 1", value: "below_1" },
    { label: "1 to 3", value: "between_1_3" },
    { label: "Above 3", value: "above_3" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],

  percentageNetProfitMargin: [
    { label: "Below 0%", value: "below_0" },
    { label: "0% to 10%", value: "between_0_10" },
    { label: "Above 10%", value: "above_10" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],

  percentageReturnOnEquity: [
    { label: "Below 10%", value: "below_10" },
    { label: "10% to 20%", value: "between_10_20" },
    { label: "Above 20%", value: "above_20" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],
  currentRatio: [
    { label: "Below 1", value: "below_1" },
    { label: "1 to 2", value: "between_1_2" },
    { label: "Above 2", value: "above_2" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ],
  debtToEquityRatio: [
    { label: "Below 50%", value: "below_50" },
    { label: "50% to 100%", value: "between_50_100" },
    { label: "Above 100%", value: "above_100" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Equal", value: "equal" },
    { label: "Between", value: "between" }
  ]
}

export const COMPANY_LIST_COLUMN_HEADER_NAME = "Company Name"

export const TRANSACTION_LIST_COLUMN_HEADER_NAME = "Target"
