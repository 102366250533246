export const ARTICLE_V3 = "article-v3" // Active
export const QUIZ = "quiz" // Active
export const TOPICS_V2 = "topics-v2" // Active
export const WEBFLOW = "webflow" // Active
export const WEBFLOW_LANDING_PAGE = "webflow-landing-page" // Active
export const HUBSPOT_CHAT_WIDGET = "hubspot" // Active
export const B2C_SUNSET = "b2c-sunset" // Active
export const LICENCE_ACTIVATION_FORM_V2 = "licence-activation-form-v2"
export const PLATFORMISATION = "platformisation"
export const USE_V2_TAG_CLASSIFICATION = "use_v2_tag_classification"
export const NAVBAR_DEALS_CLASS_NAME = "navbar_deals_class_name"
export const SIDEBAR_COUNTRIES_CLASS_NAME = "sidebar_countries_class_name"
export const SIDEBAR_INDUSTRIES_CLASS_NAME = "sidebar_industries_class_name"
export const EXTRA_SIDEBAR_ITEMS = "extra_sidebar_items"
export const EXTRA_SIDEBAR_TOP_ITEMS = "extra_sidebar_top_items"
export const SIDEBAR_MAX_DEPTH = "sidebar_max_depth"
export const FILTER_COUNTRIES_CLASS_NAME = "filter_countries_class_name"
export const FILTER_INDUSTRIES_CLASS_NAME = "filter_industries_class_name"
export const SHOW_EXTRA_FIELD_EBITDA = "show_extra_field_ebitda"
export const SHOW_EXTRA_FIELD_REVENUE = "show_extra_field_revenue"
export const ENABLE_COMPANY_PROFILE = "enable_company_profile"
export const ENABLE_COMPANY_FINANCIALS = "enable_company_financials"
export const FINANCIALS_MULTIPLES_MAPPING =
  "financials_multiples_metrics_mapping"
export const FINANCIALS_INCOME_MAPPING = "financials_income_metrics_mapping"
export const FINANCIALS_BALANCE_SHEET_MAPPING =
  "financials_balance_sheet_mapping"
export const FINANCIALS_CASH_FLOW_MAPPING = "financials_cash_flow_mapping"
export const AVAILABLE_COUNTRY_RISK_SLUGS = "available_country_risk_slugs"
export const ENABLE_COMPANY_TRANSACTIONS = "enable_company_transactions"
export const SHOW_EXTRA_COMPANY_SCREENER_COMPUTED_FIELDS =
  "show_extra_company_screener_computed_fields"
export const ENABLE_COMPANY_SCREENER_INVESTOR_FIELD =
  "enable_company_screener_investor_field"
export const ENABLE_EXTRA_FINANCIAL_FIELDS = "enable_extra_financial_fields"
export const ENABLE_LAST_PRE_MONEY_VALUATION_COLUMN =
  "enable_last_pre_money_valuation_column"
export const ENABLE_LAST_POST_MONEY_VALUTION_COLUMN =
  "enable_last_post_money_valuation_column"
export const UNCHECKED_TRANSACTION_LIST_COLUMNS_DEFAULT =
  "unchecked_transaction_list_columns_default"

export const featureFlags = {
  ARTICLE_V3,
  QUIZ,
  TOPICS_V2,
  WEBFLOW,
  WEBFLOW_LANDING_PAGE,
  HUBSPOT_CHAT_WIDGET,
  B2C_SUNSET,
  LICENCE_ACTIVATION_FORM_V2,
  PLATFORMISATION,
  USE_V2_TAG_CLASSIFICATION,
  SIDEBAR_COUNTRIES_CLASS_NAME,
  SIDEBAR_INDUSTRIES_CLASS_NAME
}

export type FeatureFlag = (typeof featureFlags)[keyof typeof featureFlags]
